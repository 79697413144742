import api from "./api";

export const TOKEN_KEY = "@skippy-token";

export const isAuthenticated = async () => {
  try {
    await api.post("/token");

    return true;
  } catch (error) {
    return false;
  }
}

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};