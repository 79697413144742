import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import api from '../../services/api';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    let mounted = true; // Indicate the mount state
    (async () => {
      try {
        await api.post("/token");
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
      }

      if (!mounted) return; // Exits if comp. is not mounted

    })();
    return () => { // Runs when component will unmount
      mounted = false;
    };
  }, []);

  return (
    isLoggedIn === null ? null : <Route {...rest} render={props => (
      isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
    )} />

  );
};

export default PrivateRoute;