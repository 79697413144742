import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select'
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'calendar-dates';

export default function AddCalendarDate() {
  const history = useHistory();
  const [season, setSeason] = useState('spring');
  const [interval, setIntervalValue] = useState('');
  const [description, setDescription] = useState('');
  const [tips, setTips] = useState([]);

  const [tipsOptions, setTipsOptions] = useState([]);
  const [tasksOptions, setTasksOptions] = useState([]);
  const [cropsOptions, setCropsOptions] = useState([]);

  const [tasks, setTasks] = useState([{}]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedCrops, setSelectedCrops] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  toast.configure();

  async function handleSelectTips(selectedTips) {
    setTips(selectedTips);
  }

  useEffect(() => {
    try {
      api.get('tips').then(response => {
        const options = response.data.map(tip => {
          return {
            value: tip._id,
            label: tip.title
          }
        });

        setTipsOptions(options);
        api.get('crops').then(response => {
          const options = response.data.map(crop => {
            return {
              value: crop._id,
              label: crop.common_name
            }
          });

          setCropsOptions(options);
          api.get('tasks').then(response => {
            const options = response.data.map(task => {
              return {
                value: task._id,
                label: task.title
              }
            });

            setTasksOptions(options);
            setIsLoading(false);
          })
        })
      })
    } catch (err) {
      toast.error('Error loading content, try again.');
      history.push(`/${API_ENDPOINT}`);
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const tipsIds = tips && tips.map(t => t.value);
      const tasks = [];

      for (let i = 0; i < selectedTasks.length; i++) {
        tasks.push({
          task: selectedTasks[i].value || null,
          crops: selectedCrops[i].map(sc => sc.value) || null
        })
      }

      const data = {
        season,
        interval,
        description,

        tips: tipsIds,
        tasks
      }

      await api.post(API_ENDPOINT, data);

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error creating a new item, try again.');
    }
    setIsLoading(false);
  }

  const handleSelectTasks = index => selectedTask => {
    const tmpTasks = selectedTasks.slice();

    tmpTasks[index] = selectedTask;

    setSelectedTasks(tmpTasks);
  }

  const handleSelectCrops = index => selected => {
    const tmpCrops = selectedCrops.slice();

    tmpCrops[index] = selected;

    setSelectedCrops(tmpCrops);
  }

  function addNewTask() {
    setTasks([...tasks, {}]);
  }

  function removeTask(_id) {
    if (tasks.length - 1 > 0) {
      setSelectedTasks(selectedTasks.filter((t, index) => index !== _id));
      setSelectedCrops(selectedCrops.filter((t, index) => index !== _id));
      setTasks(tasks.filter((t, index) => index !== _id));
    }
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Add New Calendar Date</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Season*:</label>
              <select value={season} onChange={e => setSeason(e.target.value)}>
                <option value="spring">Spring</option>
                <option value="summer">Summer</option>
                <option value="fall">Fall</option>
                <option value="winter">Winter</option>
              </select>
              <label>Interval*:</label>
              <input required type="text" onChange={e => setIntervalValue(e.target.value)} value={interval || ''} />
              <label>Description*:</label>
              <textarea required type="text" onChange={e => setDescription(e.target.value)} value={description || ''} />
              <label>Tips:</label>
              <Select
                options={tipsOptions}
                isMulti
                value={tips}
                onChange={handleSelectTips}
              />
              <h3 className="mt-4">Tasks:</h3>

              {
                tasks && Array.isArray(tasks) && tasks.map((task, index) => {
                  return (
                    <div key={index}>
                      <label>Task:</label>
                      <Select
                        options={tasksOptions}
                        value={selectedTasks[index]}
                        onChange={handleSelectTasks(index)}
                      />
                      <label>Crops:</label>
                      <Select
                        key={index}
                        options={cropsOptions}
                        isMulti
                        value={selectedCrops[index]}
                        onChange={handleSelectCrops(index)}
                      />
                      <input type="button"
                        style={{ display: 'block', width: '100%', marginTop: 20 }}
                        className="removeButton float-right"
                        onClick={() => removeTask(index)}
                        value="X"
                      />
                    </div>)

                })
              }
              <button type="button" className="addButton" onClick={() => addNewTask()}>
                <i className="fas fa-plus fa-lg"></i> Add New Task
              </button>
              <button type="submit" className="btn-success mt-4">Insert New</button>
            </form>
          </div>
        </>}
    </main>
  )
}

