import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { login } from '../../services/auth';
import './styles.css';
import logoImg from '../../assets/logo_.png';

export default function Logon() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const handleSignIn = async e => {
    e.preventDefault();
    if (!email || !password) {
      setError("Fill in email and password to continue!");
    } else {
      try {
        const response = await api.get("/login", {
          auth: {
            username: email,
            password: password
          }
        });
        login(response.data.token);
        //localStorage.setItem('TOKEN_KEY', 'betaadmin');
        history.push('/crops');
      } catch (err) {
        setError('There was a problem with the login, check your credentials.')
      }
    }
  };

  return (
    <div className="signIn-page">

      <form onSubmit={handleSignIn} >
        <img
          src={logoImg}
          alt="Skippy's Vegetable Garden"
        />
        {error && <p> {error} </p>}

        <input type="email"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)} />

        <input
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />

        <button type="submit"> Login </button>
      </form>
    </div >
  );
}
