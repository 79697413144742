import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//import './styles.css';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select'
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'tasks';
export default function EditTask() {
  const history = useHistory();
  const [tipsOptions, setTipsOptions] = useState([]);
  const [title, setTitle] = useState('');
  const [tips, setTips] = useState([]);
  const { _id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  toast.configure();

  async function handleSelect(selectedTips) {
    setTips(selectedTips);
  }

  useEffect(() => {
    try {
      api.get('tips').then(response => {
        const options = response.data.map(tip => {
          return {
            value: tip._id,
            label: tip.title
          }
        });

        setTipsOptions(options);

        api.get(`${API_ENDPOINT}/${_id}`).then(response => {
          const item = response.data;
          setTitle(item.title);
          setTips(item.tips && item.tips.map(t => {
            return t && {
              value: t._id,
              label: t.title
            }
          }) || null);

          setIsLoading(false);
        })
      })
    } catch (err) {
      toast.error('Error fetching content, try again.');
      history.push(`/${API_ENDPOINT}`);
    }


  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const tipsIds = tips && tips.map(t => t.value);

      const data = {
        title,
        tips: tipsIds
      };

      await api.put(`${API_ENDPOINT}/${_id}`, data);

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error updating the item, try again.');
    }
    setIsLoading(false);
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Edit Tip</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Title*:</label>
              <input required type="text" onChange={e => setTitle(e.target.value)} value={title || ''} />
              <label>Tips:</label>
              <Select
                options={tipsOptions}
                isMulti
                value={tips}
                onChange={handleSelect}
              />
              <button type="submit" className="btn-success mt-4">Update</button>
            </form>
          </div>
        </>}
    </main>
  )
}

