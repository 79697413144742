import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import CalendarDates from './pages/CalendarDates';
import Crops from './pages/Crops';
import Tips from './pages/Tips';
import Tasks from './pages/Tasks';
import AddCalendarDate from './pages/AddCalendarDate';
import AddCrop from './pages/AddCrop';
import AddTask from './pages/AddTask';
import AddTip from './pages/AddTip';

import PrivateRoute from './components/PrivateRoute'
import EditTask from './pages/EditTask';
import EditTip from './pages/EditTip';
import EditCrop from './pages/EditCrop';
import EditCalendarDate from './pages/EditCalendarDate';

export default function Routes() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <PrivateRoute path="/crops/add" component={AddCrop} /><PrivateRoute path="/crops/add" component={AddCrop} />
        <PrivateRoute path="/crops/edit/:_id" component={EditCrop} />
        <PrivateRoute component={Crops} path="/crops" />
        <PrivateRoute path="/tips/add" component={AddTip} />
        <PrivateRoute path="/tips/edit/:_id" component={EditTip} />
        <PrivateRoute path="/tips" component={Tips} />
        <PrivateRoute path="/tasks/add" component={AddTask} />
        <PrivateRoute path="/tasks/edit/:_id" component={EditTask} />
        <PrivateRoute path="/tasks" component={Tasks} />
        <PrivateRoute path="/calendar-dates/add" component={AddCalendarDate} />
        <PrivateRoute path="/calendar-dates/edit/:_id" component={EditCalendarDate} />
        <PrivateRoute path="/calendar-dates" component={CalendarDates} />
        <PrivateRoute component={Crops} path="/" />

      </Switch>
      <Footer />
    </BrowserRouter>
  );
}