import React from 'react';
import './styles.css';

export default function Footer() {
  return (
    <footer className="footer">
      © 2020 Copyright: <a href="https://skippysgarden.com/">Skippy's Vegetable Garden</a>
    </footer>
  )
}

