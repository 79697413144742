import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-quill/dist/quill.snow.css';
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'tips';

export default function AddTip() {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  toast.configure();

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const data = {
        title,
        description,
        link
      }

      await api.post(API_ENDPOINT, data);

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error creating a new item, try again.');
    }

    setIsLoading(false);
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Add New Tip</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Title*:</label>
              <input required type="text" onChange={e => setTitle(e.target.value)} value={title || ''} />
              <label>Description*:</label>
              <textarea required type="text" onChange={e => setDescription(e.target.value)} value={description || ''} />
              <label>Link*:</label>
              <input required type="text" onChange={e => setLink(e.target.value)} value={link || ''} />
              <button type="submit" className="btn-success mt-4">Insert New</button>
            </form>
          </div>
        </>}
    </main>
  )
}

