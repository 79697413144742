import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select'
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'tasks';

export default function AddTask() {
  const history = useHistory();
  const [tipsOptions, setTipsOptions] = useState([]);
  const [title, setTitle] = useState('');
  const [tips, setTips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  toast.configure();

  async function handleSelect(selectedTips) {
    setTips(selectedTips);
  }

  useEffect(() => {
    try {
      api.get('tips').then(response => {
        const options = response.data.map(tip => {
          return {
            value: tip._id,
            label: tip.title
          }
        });

        setTipsOptions(options);
        setIsLoading(false);
      })
    } catch (err) {
      toast.error('Error loading content, try again.');
      history.push(`/${API_ENDPOINT}`);
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const tipsIds = tips && tips.map(t => t.value);

      const data = {
        title,
        tips: tipsIds
      };

      await api.post(API_ENDPOINT, data);

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error creating a new item, try again.');
    }

    setIsLoading(false);
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Add New Task</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Title*:</label>
              <input required type="text" onChange={e => setTitle(e.target.value)} value={title || ''} />
              <label>Tips:</label>
              <Select
                options={tipsOptions}
                isMulti
                value={tips}
                onChange={handleSelect}
              />
              <button type="submit" className="btn-success mt-4">Insert New</button>
            </form>
          </div>
        </>}
    </main>
  )
}

