import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//import './styles.css';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'tips';
export default function EditTip() {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const { _id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  toast.configure();

  useEffect(() => {
    try {
      api.get(`${API_ENDPOINT}/${_id}`).then(response => {
        const item = response.data;
        setTitle(item.title);
        setDescription(item.description);
        setLink(item.link);
        setIsLoading(false);
      })
    } catch (err) {
      toast.error('Error fetching content, try again.');
      history.push(`/${API_ENDPOINT}`);
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const data = {
        title,
        description,
        link
      }

      await api.put(`${API_ENDPOINT}/${_id}`, data);
      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error updating the item, try again.');
    }

    setIsLoading(false);
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Edit Tip</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Title*:</label>
              <input required type="text" onChange={e => setTitle(e.target.value)} value={title || ''} />
              <label>Description*:</label>
              <textarea required type="text" onChange={e => setDescription(e.target.value)} value={description || ''} />
              <label>Link*:</label>
              <input required type="text" onChange={e => setLink(e.target.value)} value={link || ''} />
              <button type="submit" className="btn-success mt-4">Update</button>
            </form>
          </div>
        </>}
    </main>
  )
}

