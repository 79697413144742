import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SyncLoader from "react-spinners/SyncLoader";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const columns = [{
  title: 'ID',
  field: '_id',
  fieldType: 'text',
  hidden: true
}, {
  title: 'Season',
  field: 'season',
  required: true,
  width: 300,
  fieldType: 'text'
}, {
  title: 'Interval',
  field: 'interval',
  required: true,
  fieldType: 'text'
}, {
  title: 'Description',
  field: 'description',
  required: true,
  fieldType: 'text'
}];

const API_ENDPOINT = 'calendar-dates';

export default function Tasks() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  toast.configure();

  useEffect(() => {
    try {
      api.get(API_ENDPOINT).then(response => {
        setData(response.data);
        setIsLoading(false);
      });
    } catch (err) {
      toast.error('Error loading content, try again.');
    }
  }, []);

  async function handleDelete(item) {
    try {
      setIsLoading(true);
      await api.delete(`${API_ENDPOINT}/${item._id}`);
      setData(data.filter(i => i._id !== item._id));
      setIsLoading(false);
    } catch (err) {
      toast.error('Error');
      setIsLoading(false);
    }
  }

  async function handleDeleteMany(items) {
    try {
      setIsLoading(true);
      const _ids = items.map(i => i._id);
      await api.delete(API_ENDPOINT, {
        data: {
          _ids
        }
      });
      setData(data.filter(i => !_ids.includes(i._id)));
      setIsLoading(false);
    } catch (err) {
      toast.error('Error');
      setIsLoading(false);
    }
  }

  function deleteConfirmation(data, isMulti) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-dialog-ui'>
            <h2>Are you sure?</h2>
            <button onClick={onClose}>No</button>
            <button onClick={() => {
              isMulti ? handleDeleteMany(data) : handleDelete(data)
              onClose()
            }}>Yes, Delete it!</button>
          </div>
        )
      }
    })
  };

  return (
    <main className="container flex-grow-1 mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <div>
            <h3>Calendar Dates</h3>
            <button className="btn btn-success float-right"
              onClick={() => history.push(`/${API_ENDPOINT}/add`)}>
              <i className="fas fa-plus fa-lg"></i> Add New
            </button>
          </div>
          <div className="mt-4">
            <MaterialTable
              columns={columns}
              data={data}
              title="List"
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit',
                  onClick: (event, data) => history.push(`/${API_ENDPOINT}/edit/${data._id}`),
                  cellStyle: {
                    textAlign: "right"
                  },
                  position: 'row'
                },
                {
                  tooltip: 'Remove Selected',
                  icon: 'delete',
                  onClick: (evt, data) => deleteConfirmation(data, false),
                  position: 'row'
                },
                {
                  tooltip: 'Remove',
                  icon: 'delete',
                  onClick: (evt, data) => deleteConfirmation(data, true)
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                selection: true,
                pageSize: 20
              }}
            />
          </div>
        </>}
    </main>
  )
}

