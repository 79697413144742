import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SyncLoader from "react-spinners/SyncLoader";
const API_ENDPOINT = 'crops';

export default function AddCrop() {
  const history = useHistory();
  const [commonName, setCommonName] = useState('');
  const [latinName, setLatinName] = useState('');
  const [alternateName, setAlternateName] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  toast.configure();

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('common_name', commonName);
      formData.append('latin_name', latinName);
      formData.append('alternate_name', alternateName);
      formData.append('content', content);

      for (const key of Object.keys(images)) {
        formData.append('images', images[key])
      }

      await api.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error creating a new item, try again.');
    }
    setIsLoading(false);
  }

  return (
    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Add New Crop</h3>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <label>Common Name*:</label>
              <input required type="text" onChange={e => setCommonName(e.target.value)} value={commonName || ''} />
              <label>Latin Name:</label>
              <input type="text" onChange={e => setLatinName(e.target.value)} value={latinName || ''} />
              <label>Alternate Name:</label>
              <input type="text" onChange={e => setAlternateName(e.target.value)} value={alternateName || ''} />
              <label>Image:</label>
              <input type="file" onChange={e => setImages(e.target.files)} />
              <label>Content:</label>
              <ReactQuill theme="snow" value={content || ''} onChange={setContent} />
              <button type="submit" className="btn-success mt-4">Insert New</button>
            </form>
          </div>
        </>}
    </main>
  )
}

