import axios from 'axios';
import { getToken } from "./auth";

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
})

const errorHandler = (error) => {
  if (error.response.status === 403) {
    window.location.href = '/login';
  }

  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  return response;
}

api.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default api;