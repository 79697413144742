import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useHistory } from 'react-router-dom';
import { logout } from "../../services/auth";




export default function Navbar() {
  const history = useHistory();

  function isPageActive(pageName) {
    return (window.location.pathname.indexOf(pageName) > 0)
  }

  async function handleLogout() {
    try {
      logout();

      history.push('/');
    } catch (err) {
      toast.error('Error logging out, try again.');
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <a className="navbar-brand" href="#"><img src={logo} className="logo" /> </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className={`nav-link ${(isPageActive('calendar-dates') > 0) ? 'active' : null}`} to="/calendar-dates">
                <i className="fa fa-calendar fa-2x"></i> Calendar Dates
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${(isPageActive('tasks') > 0) ? 'active' : null}`} to="/tasks">
                <i className="fa fa-tasks fa-2x"></i> Tasks
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${(isPageActive('crops') > 0) ? 'active' : null}`} to="/crops">
                <i className="fas fa-carrot fa-2x"></i> Crops
              </Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${(isPageActive('tips') > 0) ? 'active' : null}`} to="/tips">
                <i className="fas fa-info-circle fa-2x"></i> Tips
              </Link>
            </li>
          </ul>
          <a className="nav-link" href="#" onClick={() => handleLogout()}>
            <i className="fas fa-sign-out-alt fa-2x"></i> Sign-out
          </a>
        </div>
      </div>
    </nav>
  )
}