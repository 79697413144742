import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SyncLoader from "react-spinners/SyncLoader";

const API_ENDPOINT = 'crops';
export default function EditCrop() {
  const history = useHistory();
  const [commonName, setCommonName] = useState('');
  const [latinName, setLatinName] = useState('');
  const [alternateName, setAlternateName] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState('');
  const [imagesFilenames, setImagesFileNames] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const { _id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  toast.configure();

  function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
  };

  useEffect(() => {
    try {
      api.get(`${API_ENDPOINT}/${_id}`).then(res => {
        const item = res.data;
        setCommonName(item.common_name);
        setLatinName(item.latin_name);
        setAlternateName(item.alternate_name);
        setContent(item.content);
        setImagesFileNames(item.images);
        setIsLoading(false);

        fetch(`https://api.skippysgarden.com/uploads/${item.images[0]}`, {
          method: "GET",
          headers: { Authorization: 'Bearer APP_KsP5CoUInxVG479YuWXQMhMkYnzkDx7Y' }
        }).then((response) => {
          response.arrayBuffer().then((buffer) => {
            var base64Flag = 'data:image/jpeg;base64,';
            var imageStr = arrayBufferToBase64(buffer);

            setImgSrc(base64Flag + imageStr);

            //document.querySelector('img').src = base64Flag + imageStr;
          });

          //const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;

        });
      });
    } catch (err) {
      toast.error('Error fetching content, try again.');
      history.push(`/${API_ENDPOINT}`);
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('common_name', commonName);
      formData.append('latin_name', latinName);
      formData.append('alternate_name', alternateName);
      formData.append('content', content);
      formData.append('images_filenames', imagesFilenames);

      for (const key of Object.keys(images)) {
        formData.append('images', images[key])
      }

      await api.put(`${API_ENDPOINT}/${_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      history.push(`/${API_ENDPOINT}`);
    } catch (err) {
      toast.error('Error updating the item, try again.');
    }
    setIsLoading(false);
  }

  return (


    <main className="container mt-4">
      {isLoading ?
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <SyncLoader
            size={25}
            color={"#634924"}
          />
        </div> :
        <>
          <h3>Edit Crop</h3>
          <div className="mt-4 min-100vh">

            <form onSubmit={handleSubmit}>
              <label>Common Name*:</label>
              <input required type="text" onChange={e => setCommonName(e.target.value)} value={commonName || ''} />
              <label>Latin Name:</label>
              <input type="text" onChange={e => setLatinName(e.target.value)} value={latinName || ''} />
              <label>Alternate Name:</label>
              <input type="text" onChange={e => setAlternateName(e.target.value)} value={alternateName || ''} />
              <label>Image:</label>
              <input type="file" onChange={e => setImages(e.target.files)} />

              {imagesFilenames && imagesFilenames[0] &&
                <div>
                  <label>Current Image:</label>

                  <img style={{ display: 'block' }} height='200px' src={imgSrc} />
                  <a onClick={() => setImagesFileNames([])}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}>Remove Image</a>
                </div>}

              <label>Content:</label>
              <ReactQuill theme="snow" row="20" value={content || ''} onChange={setContent} />
              <button type="submit" className="btn-success mt-4">Update</button>
            </form>
          </div>
        </>}
    </main>


  )
}

