import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import Routes from './routes';
import PrivateRoute from './components/PrivateRoute'
import Logon from './pages/Logon';
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Logon} path="/login" exact />
        <PrivateRoute component={Routes} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
